








@font-face {
    font-family: 'begumw03-regularregular';
    src: url('$fp_begum_w03_regular}/begum_w03_regular-webfont.woff2') format('woff2'),
         url('$fp_begum_w03_regular}/begum_w03_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}