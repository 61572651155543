/**
* _fonts.scss v-1.01
* champagne-mercuzot.fr
*/

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400&display=swap');



$fp_begum_w03_regular:"../webfonts/ff_begum" !default;
@import "../webfonts/ff_begum/stylesheet.scss";