/**
* _style.scss v-1.01
* champagne-mercuzot.fr
*/


// -------------------------  body ---/


// -------------------------  masthead ---/

#masthead {

  #logoBar-sm {
      //height:rem-calc(120);
      display:block;
      position: relative;
      padding-top: rem-calc(4);padding-bottom: rem-calc(4);
      font-size: rem-calc(12); 
      border-bottom: 1px solid rgb(255, 255, 255);
  }
  
  
}

// -------------------------  precontent ---/

#top{
  margin-top: $sticky-shrink-menu-height;
  
    @include breakpoint(medium) {
      margin-top: $sticky-shrink-header-height;
    }
  }

#top.acc{
  margin-top: $sticky-shrink-menu-height;
  
    @include breakpoint(medium) {

    }
  }

  .top-container{
  display: flex;
  align-items: center;    
  }

  //> div{
  //display: flex;
  //justify-content: center;    
  //}


// -------------------------  main ---/
#main{
  //border-right: 4px solid $dark-gray;
  padding: rem-calc(0 60 0 60);
  //background-color: $mxnf1;
  
    @include breakpoint(medium) {
      //border-right: 10px solid $dark-gray;
      padding: rem-calc(80 60 80 60);
    }
}

// -------------------------  padding_tb ---/

.padding_tb {
    padding-top: rem-calc(60);
    padding-bottom: rem-calc(40);
}



// -------------------------  section-- media-object-image -- moi---/
.moi{
  //border-top: 8px solid $mxnf3;
  //border-bottom: 8px solid $mxnf3;

  .media-object-section.small-order-1 {
    padding-right: 1rem;
  }
  .media-object-section.small-order-2 {
    padding-right: 0;
  }


  a{
    width: 100%;
    color: $secondary-color;
  }

  
}


/**************************************************************  toptitle */
#toptitle{
  margin-top:rem-calc(18);
  margin-bottom:rem-calc(18);

  @include breakpoint(medium) {
    margin-top:rem-calc(18);
    margin-bottom:rem-calc(80);
  }

}

h1,h2,h3 {
  color: $mxnf1;
  margin-bottom: rem-calc(30);
  font-weight: 200;
}
h4,h5,h6 {

}


/**************************************************************  widgets : widget-container */
.widget-container{
  padding-top:rem-calc(18);
  padding-bottom:rem-calc(18);

  @include breakpoint(medium) {
    padding-top:rem-calc(60);
    padding-bottom:rem-calc(60);
  }

}

h1,h2,h3 {
  color: $mxnf1;
  padding-bottom: rem-calc(24);
  text-transform: uppercase;
}
h4,h5,h6 {
  color: $mxnf1;
  padding-bottom: rem-calc(24);
  text-transform: uppercase;
}


/**************************************************************  widget_actualites */
#widget_actualites{
  background-color: rgba($secondary-color, 1.0);

  h3 {
    color: $white;
  }

  .actu_header {
    display: block;
    flex-direction: column;
    flex-wrap: nowrap;

    h4 {
      color: #8d6e2d;
      font-family: 'begum_w03_regular', serif;
      font-size: 96%;
      //letter-spacing: .2rem;
      font-weight: 600;
      //line-height: 30px
      padding:rem-calc(0 8 0 0);
    }

    h4::before {
    content: url('../img/fb/fb_profile_image_s.png');  
    //content: url('../img/fb/fb_profile_image_s.png');
    //content: -webkit-image-set(
    //  url('../img/fb/fb_profile_image_s.webp'),
    //  url('../img/fb/fb_profile_image_s.png'),      
    //  );
    //background-size: cover; 
    //width:rem-calc(30);height:rem-calc(30);
    padding:rem-calc(0 8 0 0);
    vertical-align: -50%;
    }

    p {
      color: $secondary-color;
      font-size: 80%;

    }
  }

  
  .actu_section {
    display: block;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: rem-calc(100);

    p {
      color: $secondary-color;
      font-size: 80%;

    }
  }

}


// -------------------------  champagnes ---/
#champagne_cont{
  border-right: 4px solid $dark-gray;
  //padding: rem-calc(0 60 0 60);
  background-color: $mxnf1;
  
    @include breakpoint(medium) {
      border-right: 10px solid $dark-gray;
      padding: rem-calc(80 60 80 60);
    }


    .ch_block{
    position: relative;
    }


    .chtitle_block{
    font-family: Helvetica, Arial, FreeSans, sans-serif;

    }

        .chtitle_block h1,
        .chtitle_block h2{
        margin: 0;
        padding:rem-calc(12 12);
        position: relative;
        display: inline-block;
        vertical-align:middle;
        color: $textColor;

          @include breakpoint(medium down) {
          padding:rem-calc(12 0);
          }

        }

        .chtitle_block h1::before,
        .chtitle_block h2::before {

          display: inline-block;
          vertical-align:middle;
          content: '';    
          background: url('../img/logos/champGris_bird.png');
          width: rem-calc(80);
          height: rem-calc(80);   
          //content: -webkit-image-set(
          //  url('../img/logos/champBlu_bird.webp'),
          //  url('../img/logos/champBlu_bird.png'),      
          //  );
          background-size: cover;
        }
    


    .ch_contents_block{
    padding-top: rem-calc(20);
    font-family: Helvetica, Arial, FreeSans, sans-serif;
    color: $textLight;
    text-align: justify;
    font-size: 100%;
    //font-weight: 500;
    //opacity: 0.3;
    }


    .chImg_block{
    display: flex;
    //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
    justify-content: center;
    align-content: center;
    align-items: center;

       img{
      min-width: rem-calc(200);
        }

    }


  .ch_contents_block2{
    width: 100%;height: 100%;
    //margin: rem-calc(0 0 0 0);
    //padding: rem-calc(60 0 60 0);

    display: flex;
    //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
    justify-content: center;
    align-content: center;
    align-items: center;


    background-image: url('../img/logos/champGris_tree.png') !important;
    //background-image: -webkit-image-set(
    //url('../img/logos/champBlu_tree.webp'),
    //url('../img/logos/champBlu_tree.png'),      
    //)!important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;

    .block2_text{
    padding: rem-calc(60 0 60 0);
    font-family: Helvetica, Arial, FreeSans, sans-serif;
    color: $textLight;
    text-align: center;
    font-size: 200%;
    //font-weight: 500;
    //opacity: 0.3;

      @include breakpoint(medium) {
        font-size: 300%;
      }
    }

  }


} // /#champagne_cont


// blu-tentationBrut
$blu-tentationBrut: #21379F;
body.blu-tentationBrut #header{
  border-color: $blu-tentationBrut;
}
body.blu-tentationBrut #footer{
  border-color: $blu-tentationBrut;
}

#champagne_cont.blu-tentationBrut{
  border-color: $blu-tentationBrut;

  .chtitle_block h1::before,
  .chtitle_block h2::before {
    background: url('../img/logos/blu-tentationBrut_bird.png'); 
    //content: -webkit-image-set(
    //  url('../img/logos/blu-tentationBrut_bird.webp'),
    //  url('../img/logos/blu-tentationBrut_bird.png'),      
    //  );
    background-size: cover;    
  }

  .ch_contents_block2{
    background-image: url('../img/logos/blu-tentationBrut_tree.png') !important;
    //background-image: -webkit-image-set(
    //url('../img/logos/blu-tentationBrut_tree.webp'),
    //url('../img/logos/blu-tentationBrut_tree.png'),      
    //)!important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;

  }

}


// grisClair1-tentationExtraBrut
$grisClair1-tentationExtraBrut: #B3B2B2;
body.grisClair1-tentationExtraBrut #header{
  border-color: $grisClair1-tentationExtraBrut;
}
body.grisClair1-tentationExtraBrut #footer{
  border-color: $grisClair1-tentationExtraBrut;
}

#champagne_cont.grisClair1-tentationExtraBrut{
  border-color: $grisClair1-tentationExtraBrut;

  .chtitle_block h1::before,
  .chtitle_block h2::before {
    background: url('../img/logos/grisClair1-tentationExtraBrut_bird.png'); 
    //content: -webkit-image-set(
    //  url('../img/logos/grisClair1-tentationExtraBrut_bird.webp'),
    //  url('../img/logos/grisClair1-tentationExtraBrut_bird.png'),      
    //  );
    background-size: cover;    
  }

  .ch_contents_block2{
    background-image: url('../img/logos/grisClair1-tentationExtraBrut_tree.png') !important;
    //background-image: -webkit-image-set(
    //url('../img/logos/grisClair1-tentationExtraBrut_tree.webp'),
    //url('../img/logos/grisClair1-tentationExtraBrut_tree.png'),      
    //)!important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;

  }

}


// grisClair2-noirIntense
$grisClair2-noirIntense: #84827D;
body.grisClair2-noirIntense #header{
  border-color: $grisClair2-noirIntense;
}
body.grisClair2-noirIntense #footer{
  border-color: $grisClair2-noirIntense;
}

#champagne_cont.grisClair2-noirIntense{
  border-color: $grisClair2-noirIntense;

  .chtitle_block h1::before,
  .chtitle_block h2::before {
    background: url('../img/logos/grisClair2-noirIntense_bird.png'); 
    //content: -webkit-image-set(
    //  url('../img/logos/grisClair2-noirIntense.webp'),
    //  url('../img/logos/grisClair2-noirIntense.png'),      
    //  );
    background-size: cover;    
  }

  .ch_contents_block2{
    background-image: url('../img/logos/grisClair2-noirIntense_tree.png') !important;
    //background-image: -webkit-image-set(
    //url('../img/logos/grisClair2-noirIntense_tree.webp'),
    //url('../img/logos/grisClair2-noirIntense_tree.png'),      
    //)!important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;

  }

}


// gold-intenseConfidenses
$gold-intenseConfidenses: #ECAE60;
body.gold-intenseConfidenses #header{
  border-color: $gold-intenseConfidenses;
}
body.gold-intenseConfidenses #footer{
  border-color: $gold-intenseConfidenses;
}

#champagne_cont.gold-intenseConfidenses{
  border-color: $gold-intenseConfidenses;

  .chtitle_block h1::before,
  .chtitle_block h2::before {
    background: url('../img/logos/gold-intenseConfidenses_bird.png'); 
    //content: -webkit-image-set(
    //  url('../img/logos/gold-intenseConfidenses_bird.webp'),
    //  url('../img/logos/gold-intenseConfidenses_bird.png'),      
    //  );
    background-size: cover;    
  }

  .ch_contents_block2{
    background-image: url('../img/logos/gold-intenseConfidenses_tree.png') !important;
    //background-image: -webkit-image-set(
    //url('../img/logos/gold-intenseConfidenses_tree.webp'),
    //url('../img/logos/gold-intenseConfidenses_tree.png'),      
    //)!important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;

  }

}


// red1-roseIntense
$red1-roseIntense: #BC372E;
body.red1-roseIntense #header{
  border-color: $red1-roseIntense;
}
body.red1-roseIntense #footer{
  border-color: $red1-roseIntense;
}

#champagne_cont.red1-roseIntense{
  border-color: $red1-roseIntense;

  .chtitle_block h1::before,
  .chtitle_block h2::before {
    background: url('../img/logos/red1-roseIntense_bird.png'); 
    //content: -webkit-image-set(
    //  url('../img/logos/red1-roseIntense_bird.webp'),
    //  url('../img/logos/red1-roseIntense_bird.png'),      
    //  );
    background-size: cover;    
  }

  .ch_contents_block2{
    background-image: url('../img/logos/red1-roseIntense_tree.png') !important;
    //background-image: -webkit-image-set(
    //url('../img/logos/red1-roseIntense_tree.webp'),
    //url('../img/logos/red1-roseIntense_tree.png'),      
    //)!important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;

  }

}


// pink-marionBrutRose
$pink-marionBrutRose: #B66693;
body.pink-marionBrutRose #header{
  border-color: $pink-marionBrutRose;
}
body.pink-marionBrutRose #footer{
 border-color: $pink-marionBrutRose;
}

#champagne_cont.pink-marionBrutRose{
  border-color: $pink-marionBrutRose;

  .chtitle_block h1::before,
  .chtitle_block h2::before {
    background: url('../img/logos/pink-marionBrutRose_bird.png'); 
    //content: -webkit-image-set(
    //  url('../img/logos/pink-marionBrutRose_bird.webp'),
    //  url('../img/logos/pink-marionBrutRose_bird.png'),      
    //  );
    background-size: cover;    
  }

  .ch_contents_block2{
    background-image: url('../img/logos/pink-marionBrutRose_tree.png') !important;
    //background-image: -webkit-image-set(
    //url('../img/logos/pink-marionBrutRose_tree.webp'),
    //url('../img/logos/pink-marionBrutRose_tree.png'),      
    //)!important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;

  }

}


// brown-marionBrut
$brown-marionBrut: #583A1B;
body.brown-marionBrut #header{
  border-color: $brown-marionBrut;
}
body.brown-marionBrut #footer{
  border-color: $brown-marionBrut;
}

#champagne_cont.brown-marionBrut{
  border-color: $brown-marionBrut;

  .chtitle_block h1::before,
  .chtitle_block h2::before {
    background: url('../img/logos/brown-marionBrut_bird.png'); 
    //content: -webkit-image-set(
    //  url('../img/logos/brown-marionBrut_bird.webp'),
    //  url('../img/logos/brown-marionBrut_bird.png'),      
    //  );
    background-size: cover;    
  }

  .ch_contents_block2{
    background-image: url('../img/logos/brown-marionBrut_tree.png') !important;
    //background-image: -webkit-image-set(
    //url('../img/logos/brown-marionBrut_tree.webp'),
    //url('../img/logos/brown-marionBrut_tree.png'),      
    //)!important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;

  }

}


// -------------------------  parallax ---/
#parallax{
    //padding-top: rem-calc(48);
} //#parallax
  
  .parallax_bg{
    padding: rem-calc(100 0);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: auto auto;
    background-position: top 0 right 10%;;

    
    @include breakpoint(medium) {
      padding: rem-calc(100 0);
    }

  }

  .parallax_section {
    padding: rem-calc(18 0); 

    .text_dgrey {
      color: #212529;
    }

    h2.text_white,
    .text_white {
      color: $white;
      font-family: 'begum_w03_regular', serif;
      //font-size: rem-calc(16);
      //letter-spacing: .2rem;
      font-weight: 600;
    }

    .text_primary {
      color: $primary-color;
    }

    .text_secondary {
      color: $secondary-color;
    }    

    h2 {
      color: #8d6e2d;
    }
    p {
      text-align: justify;
    }
  }

  #parallax-nos-champagnes .parallax_bg{
    background-image: url('../img/parallax/cheers.jpg') !important;
    background-image: -webkit-image-set(
    url('../img/parallax/cheers.webp'),
    url('../img/parallax/cheers.png'),      
    )!important;
    background-color: rgba(0,0,7,1.0);
  }

  #parallax-champagne2 .parallax_bg{
    background-image: url('../img/parallax/histoire.jpg') !important;
    background-image: -webkit-image-set(
    url('../img/parallax/histoire.webp'),
    url('../img/parallax/histoire.jpg'),      
    ) !important;
  }


/**************************************************************  list-champagnes */
#list-champagnes{
  padding: rem-calc(100 0);
  border-top: 1px solid rgba(238,204,159,1);
  border-bottom: 1px solid rgba(238,204,159,1);
  background-color: rgba($primary-color, 0.2);

 .ch_block{
  position: relative;
  }

 .chtext_block{
  color: $secondary-color;
  text-align: left;
  position: absolute;
  transform: rotate(-90deg);
  bottom: 35%;
  left: 12px;
  transform-origin: 0 0;
  //opacity: .5;
  font-size: 13px;
  text-transform: uppercase;
  white-space: nowrap;
  }

  a{

  }  

  a:hover .chImg_block{
    transform: scale(1.15);
    transition: 0.3s;

  } 


}


// -------------------------  Contact ---/
.contact_aside{
  margin:rem-calc(18 3);
  padding:rem-calc(18);
  box-shadow: 0px 0px 3px 3px rgba($mxnf1, 0.2);


  h5{
    padding-bottom:rem-calc(6);
  }

  @include breakpoint(medium) {
    margin:rem-calc(12 18);    
    padding:rem-calc(18);
  }
}

.contactFset{
  margin:rem-calc(18 3);
  margin-bottom:rem-calc(12);

  @include breakpoint(medium) {
    margin-top:rem-calc(24);
    margin-bottom:rem-calc(36);
  }

  .civil_ref{
    padding:rem-calc(6 0);
  }

  .errors{
    margin-top: rem-calc(-12);
    margin-bottom: rem-calc(18);    
  display: flex;
  flex-wrap: wrap;

    i{
    color: #e4134f;
    line-height: rem-calc(14);
    font-size: rem-calc(36);
    }
    p{
    margin:0;
    padding:rem-calc(6 6 6 6);
    color: $white;
    background-color: #e4134f;
    }
  }

}

.validation_error_message{
  display: flex;
  flex-direction: column;
  align-items: center;
  //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
    ul{
      padding: rem-calc(12 0);
    }
}


/**************************************************************  #footer */
$footer-bg: $mxnf2;

#footer {
    
    padding-top:rem-calc(18);padding-bottom:rem-calc(6);
    background-color: $footer-bg;
    color: darken($textLight, 10%);

.sig-line {
  display: flex;
  //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
  //flex-direction: row | row-reverse | column | column-reverse;
  //flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top:rem-calc(18);margin-bottom:rem-calc(18);

  @include breakpoint(medium) {
    margin-top: rem-calc(18);margin-bottom: rem-calc(36);
  }

  .sig-line-inner {
    text-align: center;

    .sig1 {
      margin: 0;
      padding: 0;
      line-height: 1.2rem;
      letter-spacing: .5rem;
      font-size: rem-calc(12);
      font-weight: 300;
      color: lighten($footer-bg, 75%);
    }
    .sig2 {
      margin: 0;
      padding: 0;
      font-family: 'begum_w03_regular', serif;
      font-size: rem-calc(16);
      letter-spacing: .2rem;
      font-weight: 600;
      color: lighten($footer-bg, 75%);
    }
    .sig3 {
      margin: 0;
      padding: 0;
      letter-spacing: .5rem;
      font-size: rem-calc(8);
      font-weight: 200;
      color: lighten($footer-bg, 75%);
    }
  }
}

.sig-line::before, .sig-line::after {
    content: '';
    display: inline-block;
    flex-grow: 1;
    margin-top: 0.8rem;
    background: lighten($footer-bg, 30%);
    height: 2px;
    margin: 0;
    margin-right: rem-calc(24);
    margin-left: rem-calc(24);
  }

  .footer-title {
    color: $white;
    margin-top: rem-calc(24);
    margin-bottom: rem-calc(12);
    padding-bottom: rem-calc(6);
    border-bottom: 1px solid lighten($footer-bg, 20%);
    text-align: left;

    @include breakpoint(medium) {
      margin-top: rem-calc(0);
      border: none;
    }
  }

  .footer-block {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .fa {
      //font-size: 2rem;
      color: lighten($footer-bg, 45%);
    }

    p {
      margin: 0;
      margin-left: 1rem;
      //line-height: 1.125rem;
    }
  }


  .footer-menu{
  flex-wrap: wrap;
  white-space: nowrap;
  //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
  justify-content: end;

  overflow: hidden;
  text-overflow: ellipsis; 
  padding-bottom: rem-calc(24);


    li{
      padding:rem-calc(0 0 16 0);
      a{
        color: $anchor-color;//lighten($footer-bg, 45%);
        padding:rem-calc(0 6 0 6);
        line-height: 1.6;
      }
      a.is-active{
        color: $footer-bg;
      }
      &:hover a{
        color: $anchor-color-hover;
      }   
    }

  }

  .footer-social{
    border-bottom: 1px solid lighten($footer-bg, 20%);
    //align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
    justify-content: center;

    @include breakpoint(medium) {
      border: none;
    }
  }

  .up-arrow{
  color: $white;
  width: 100%;

  display: flex;
  justify-content: flex-end;

      a{
        color: $white;
        padding:rem-calc(6 6 3 6);
        &:hover {
          color: $anchor-color-hover;
        }          
      } 

  }

  .footer-notice{
  color: $white;
  text-align: center;
  margin: 0;
  padding: 0;
  //margin-left: 1rem;
  //line-height: 1.0rem;

    @include breakpoint(medium) {
      text-align: center;
    }

  }

}


/************************************************************** OFFLINE */
#footer.offline {
  position: absolute;
  bottom: 0;
  width: 100%

}
